@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);

html {
  scroll-behavior: smooth;
}


*{
  font-family: 'Inconsolata';
  
}

* img{
  /* image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated; */
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

span{
  font-family: 'Press Start 2P', cursive !important;
  position:relative;
  top: 4px;
}

.minted{
  color: #D83AEB;
}

.to-mint{
  color: #250B28;

}

.btn {
  color: white !important;
  font-weight: 900 !important;
  background-color: #D83AEB !important;
  border-color: #D83AEB !important;
  padding: 8px, 16px, 8px, 16px !important;
  -webkit-appearance: none !important;
  -webkit-border-radius: 0px !important;
}

.btn-secondary {
  color: #D83AEB !important;
  font-weight: 900 !important;
  background-color: transparent !important; 
  border: 2px solid #F7D8FB !important;
  padding: 8px, 16px, 8px, 16px !important;
  -webkit-appearance: none !important;
  -webkit-border-radius: 0px !important;
}

.btn:hover {
  background-color: #E26BF0 !important;
  color: white !important;
}

.btn-secondary:hover {
  color: #D83AEB !important;
  border: 2px solid #D83AEB !important;
  background-color: #F7D8FB !important;
}


h1 {
  color: #250B28 !important;
  font-weight: 900 !important;
  font-size: 40px !important;
  line-height: 48px !important;
}
h2 {
  color: #250B28 !important;
  font-weight: 900 !important;
  font-size: 28px !important;
  line-height: 38px !important;
}
h3 {
  color: #250B28 !important;
  font-weight: 900 !important;
  font-size: 22px !important;
  line-height: 30px !important;
}
h4 {
  color: #250B28 !important;
  font-weight: 900 !important;
  font-size: 16px !important;
  line-height: 26px !important;
}

h5{
  padding-top: 32px;
  padding-bottom: 32px;

  color: #D83AEB !important;
  font-weight: 400 !important;
  font-family: 'Press Start 2P', cursive !important;
  font-size: 16px !important;
  line-height: 16px !important;
}

a {
  color: #D83AEB !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 26px !important;
}

p{  
  color: #5C485E !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 26px !important;
}

.hero img{
  max-height: 250px;
}

.hero.row{
  padding-top: 0px !important;
}

.hero h1{
  padding-top: 80px;
}

.hero{
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;

}



.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.modal-backdrop{
    opacity: 0.2 !important;
}

.modal-header{
    padding: 0 !important;
    border: none !important;    
}

.modal-header p{
    padding-bottom: 24px  !important;
    padding-top: 8px  !important;

    margin-bottom: 0px  !important;

}

.modal-header h3 img{

    padding-right: 8px;
    
}

.modal h3{
    color: white !important;
    margin-bottom: 0px !important;
}

.modal h4{
    color: white !important;
}

.modal p{
    color: white !important;
}

.modal-body{
    padding: 0px !important;

}

.modal-content{
    padding: 40px;
    border: none !important;
    color: white;
    background-color: #250B28 !important;
    border-radius: 0% !important; 
    box-shadow: -10px 0px 0px -5px #250B28, 10px 0px 0px -5px #250B28, 15px 5px 0px -5px #250B283D, 5px 5px 0px 0px #250B283D;

}

.modal-wallet-btn
{
    color: white !important;
    padding: 0px !important;
    border: none !important;
    background-color: #5C485E !important;
}

.modal-wallet-btn:hover
{
    color: black !important;
    background-color: white !important;
}

.modal-wallet-btn img{
    padding: 16px;
}

.modal .close{
    color: white !important;
    padding-right: 15px !important;
}

.modal-footer{
    padding: 0px !important;
    margin-top: 40px !important;
    display: inline-block !important;
    border-color: #5C485E  !important;
}



.modal-footer-socials{
    display: inline;
    padding: 0 !important;
    margin-left: auto !important;
    text-align: left !important;
}

.modal-footer-socials img{
    text-align: left !important;
    max-height: 24px;
    margin-top: 16px;
    margin-right: 8px;


}

.modal-dc{
    margin-top: 24px;
}

.connected-body p {
    display: inline !important;
    margin-top: 16px  !important;
}

.connected-header{
    margin-bottom: 8px;
}

.connect-btn{
    margin-left: 16px !important;
}

@media screen and (max-width: 992px) {
    .connect-btn{
       
    }

    .connect-container{
        margin-bottom: 24px;
        
        
    }
}


a.nav-link{
    size: 16px;
    text-align: center;
    font-weight: 700;
    padding: 27px;
    padding-left: 12px !important;
    padding-right: 12px !important;    
    color: #A89DA9 !important;
    line-height: 26px;
}
a.nav-link:hover{
    color: white !important;
    box-shadow: inset 0 -4px 0 0 #5C485E ; 
}

a.is-active{
    color: white  !important;
    box-shadow: inset 0 -4px 0 0#D83AEB; 
}

/* .navbar{
    height: 80px;
} */

.nav-bg{
    background-color: #250B28 !important;
}

.navbar-toggler{
    background-color:#250B28 !important
    ;
}

.navbar-toggler-icon{
    top: auto;
    bottom: auto;
    right: 8px;

    color: white !important;
    text-shadow: 1px 1px 1px #ccc;

}


.navbar-toggler-icon{
    border:#250B28 !important;
    background-color:#250B28 !important;
}

.navbar{
    padding-top: 0px !important;
    padding-bottom: 0px !important;

}
.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
  }
  
.custom-toggler.navbar-toggler {
    padding-right: 0px !important;
  } 

.navbar .connect-btn{
    background-color: #250B28 !important;
    border: 2px solid #5C485E !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    

}

.navbar .connect-btn:hover{
    border-color: white !important;
    background-color: #5C485E !important;
    
}

.navbar .connect-btn img{
    padding-right: 8px;
    position: relative;
    top: -2px;
}

.buy-now{
    padding-top: 8px !important;
    padding-bottom: 8px !important;

}



  @media screen and (max-width: 992px) {
    .brand{
        padding-top: 16px !important;
        padding-bottom: 16px !important;

    }



  }


.attr-text{
    padding-top: 15px;
}


.attr-kind-row{
    padding-top: 23px;
    padding-bottom: 22px;

}
.attr-kind-row:first-of-type{
    padding-top: 120px !important;
}

.attributes .attr-kind-row{
    padding-top: 80px !important;
}

.attributes .col-md-4{
    padding-bottom: 16px;
}

.rarity-dist .rarity-artifact{
    background-color: #CDAC12 !important;
}

.rarity-dist .rarity-legendary{
    background-color: #D66600 !important;
}

.rarity-dist .rarity-epic{
    background-color: #982BB4 !important;
}

.rarity-dist .rarity-rare{
    background-color: #275FB4 !important;
}


.rarity-dist .rarity-uncommon{
    background-color:#20C76D !important;
}

.rarity-dist .rarity-common{
    background-color: #CFCC89 !important;
}

.rarity-dist img{
    margin-top: 42px;
    max-height: 16px;
}

.rarity-dist h4{
    padding-top: 12px !important;
}

.rarity-dist p{
    padding-top: 0px !important;
}

.attr-kind-row img
{
    max-height: 150px;
}

.attr-kind-row:last-of-type{
    padding-bottom: 120px !important;
}

.attributes .hero{
    padding-bottom: 0px !important;
}

.attributes .nav{
    padding-top: 80px;
}

.attributes .nav-link {
    border: none !important;
    background: none !important;
    border-radius: none !important;

}

.attributes .nav-tabs{
    border-bottom: none !important;
}

.attributes .nav-item{
    color:#A89DA9 !important;

}

.attributes .nav-link{
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 24px !important;



    margin-right: 24px !important;
    margin-bottom: 0px !important;


}

.attributes .nav-item .active{
    color: #D83AEB !important;
    box-shadow: inset 0 -4px 0 0#D83AEB; 
}
.attributes .nav-link:hover{
    color: #D83AEB !important;
    box-shadow: inset 0 -4px 0 0#D83AEB60; 

}

@media screen and (max-width: 992px) {
    .rarity-dist img{
        margin-top: 16px;
    }
    .rarity-dist p{
        padding-top: 10px !important;
    }
    .rarity-tag{
        text-align: left !important;
    }

    .rarity-pct{
        text-align: right !important;
    }
  }

.my-sollamas span {
  color: #d83aeb;
  font-size: 12px;
  margin-left: 10px;
  top: 2px;
}

.card-container {
  display: grid;
  -webkit-perspective: 700px;
          perspective: 700px;
}

.card-flip {
  display: grid;
  grid-template: 1fr / 1fr;
  grid-template-areas: "frontAndBack";
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  transition: all 0.7s ease;
}

.card-flip div {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.front {
  grid-area: frontAndBack;
}
.back {
  grid-area: frontAndBack;
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
}
.card-container:hover .card-flip {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.my-sollamas-card {
  margin-bottom: 40px;
}

.my-sollamas-card p {
  padding: 16px;
}

.my-sollamas-card h3 {
  padding-top: 8px;
}

.my-sollamas-card img {
  margin-bottom: 20px !important;
}

.my-sollamas-card span {
  line-height: 4px !important;
}

.my-sollamas {
  background: #fef5ff;
}

.my-sollamas .hero {
  border-bottom: 1px solid #f7d8fb !important;
}

.my-sollamas .spinner-border {
  margin-top: 24px;
}

.my-sollamas .revive-modal {
  width: 100px !important;
}

.modal-llama {
  max-width: 150px !important;
}

.my-sollamas .nav {
  padding-top: 80px;
}

.my-sollamas .nav-link {
  border: none !important;
  background: none !important;
  border-radius: none !important;
}

.my-sollamas .nav-tabs {
  border-bottom: none !important;
}

.my-sollamas .nav-item {
  color: #a89da9 !important;
}

.my-sollamas .nav-link {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 24px !important;

  margin-right: 24px !important;
  margin-bottom: 0px !important;
}

.my-sollamas .nav-item .active {
  color: #d83aeb !important;
  box-shadow: inset 0 -4px 0 0#D83AEB;
}
.my-sollamas .nav-link:hover {
  color: #d83aeb !important;
  box-shadow: inset 0 -4px 0 0#D83AEB60;
}

.last-accordian{
    padding-bottom: 80px;
}

.faqs .accordion{
    
}

.faqs .card{
    background-color: white !important;
    border-left: None !important;
    border-right: None !important;


}

.faqs .card  h4{
    margin: 0 !important;
}

.faqs .card-header{
    border: None !important;
    background-color: white !important;
    padding-top: 23px !important;
    padding-bottom: 23px !important;

}


.sold-out{
    margin-top: 24px !important;
    color: #D83AEB !important
}

.progress-bar{
    background-color: #D83AEB !important;
}

.progress{
    border-radius: 0% !important;
    background-color: #F7D8FB !important;
}

.llama-progress{
    margin-top: 32px;
}


.hero.row{
    background-color: #FEF5FF;
    padding-top: 80px;
    padding-bottom: 80px;

}
.hero-image{
    background-color: #FEF5FF;
    overflow: hidden;
}

.hero p{
    padding-top: 16px;
}



.home .btn{
    margin-top: 40px;
    margin-bottom: 70px;
}

.home p:first-of-type {
    padding-top: 20px;
}

.pack-copy{
    padding-bottom: 40px;
}

.packs hr{
    width: 65% !important;
}

.packs p{
    padding-top: 0px !important;

}

.packs .card-img{
    padding: 24px;
}

.cta-span{
    
    display: block !important;
    margin-top: 40px;
    margin-bottom: 40px;


    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 32px;
    padding-right: 32px;

    display: inline-block;

    background-color: #F7D8FB;
    color: #D83AEB;

    font-weight: 400 !important;
    font-family: 'Press Start 2P', cursive !important;
    font-size: 16px !important;
    line-height: 24px !important; 
    box-shadow: -10px 0px 0px -5px #F7D8FB, 10px 0px 0px -5px #F7D8FB;

}

.padded-row{
    padding-top: 120px !important;
    padding-bottom: 120px !important;
    
}

.attr-row img{
    padding: 20px;
}

.padded-row-bottom{
    padding-bottom: 120px !important;
}

.padded-row-top{
    padding-top: 120px !important;
}

.cta-remaining-row h4{
    display: inline-block !important;
    position:relative;
    top: 0px;
    padding-right: 10px;
    padding-left: 10px;
 
  }
 
.slider {
    width: 100%;
    overflow: hidden;
    background-color: #FEF5FF;
    padding-bottom: 40px;
}
.slider .slider-row {
    width: 4968px;
    height: 216px;
    background-size: 4968px 216px;
    -webkit-animation: slide 80s linear infinite;
            animation: slide 80s linear infinite;
    
}


.home .hero button{
    margin-left: 8px;
    margin-right: 8px;

}


@-webkit-keyframes slide {
    from {
        background-position-x: 0;
   }
    to {
        background-position-x: 4968px;
   }
}


@keyframes slide {
    from {
        background-position-x: 0;
   }
    to {
        background-position-x: 4968px;
   }
}





@media screen and (max-width: 992px) {
    .padded-row-bottom h2{
        padding-top: 24px;
    }
    .padded-row h2{
        padding-top: 24px;
    }

    .slider .slider-row {
        min-height:100%;
        /* background-image: url("./llamariver_m.png"); */
        /* display: inline-block; */
        /* width: 2592px;
        height: 216px;
        background-size: 2592px 216x; */
        -webkit-animation: slide 60s linear infinite;
                animation: slide 60s linear infinite;


    }

    @-webkit-keyframes slide {
        0% {
            -webkit-transform: translateX(-2000px);
        }
        50% {
            -webkit-transform: translateX(-1000px);
        }
        100% {
            -webkit-transform: translateX(0px);
        }
    }

    @keyframes slide {
        0% {
            -webkit-transform: translateX(-2000px);
        }
        50% {
            -webkit-transform: translateX(-1000px);
        }
        100% {
            -webkit-transform: translateX(0px);
        }
    }
    
  }

.footer {
    border-top: 1px solid rgba(128, 128, 128, 0.179);
    max-height: 144px;
}

.footer .brand{
    margin-bottom: 20px;
}

.footer .brand img{

}

.footer .sexyboi img{
    max-height: 120px;
    margin-top: 20px;
}

.footer .navbar-brand{
    padding-bottom: 0 !important;
    padding-top: 0 !important;

}

.footer .socials img{
    padding-right: 20px;
}

.footer-nav-link{
    display: block;
    color: #5C485E !important;
    padding-top: 10px;
    padding-bottom: 10px;
    
}

.footer-is-active{

}

.footer-nav-link:hover{
    color: #E26BF0 !important;
    text-decoration: none;

}

.footer-header{
    color: #EFB0F7 !important;
    font-weight: 900 !important;
}

.bio{
    margin-bottom: 88px!important;
}

@media screen and (max-width: 992px) {
    .footer-links-menu{
        margin-top: 50px !important;
    }
  
  }

.cta-container{
    background-color: #FEF5FF;
}

.cta-container img{
    padding-right: 38px;
    padding-left: 38px;
}

.cta-container .btn{
    margin-top: 40px !important;
}

.cta-container p {
    padding-top: 46px;
}
.roadmap{
    border-top: 1px solid #5C485E;

    background-color: #250B28 !important;
}

.roadmap-header{
    border-bottom: 1px solid #5C485E !important;

}

.roadmap-header .container{
  border-bottom: none !important;

}

.roadmap-header h1{
    padding-top: 80px;
    color: white !important;

}

.roadmap-header p{
    color: white !important;
    padding-top: 16px;
    padding-bottom: 70px;

}



/* TIMELINE
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.timeline ul {
    padding: 120px 0;
  }
  
  .timeline ul li {
    list-style-type: none;
    position: relative;
    width: 6px;
    margin: 0 auto;
    padding-top: 50px;
    background: #5C485E;
  }
  
  .timeline ul li::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 53%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 20px;
    height: 30px;
    /* border-radius: 50%; */
    background: inherit;
    z-index: 1; 
    box-shadow: -10px 0px 0px -5px #5C485E, 10px 0px 0px -5px #5C485E;
  }
  
  .timeline ul li div {
    position: relative;
    bottom: 0;
    width: 500px;
    padding: 15px;
    background: white; 
    box-shadow: -10px 0px 0px -5px white, 10px 0px 0px -5px white;
  }

  
  .timeline ul li:nth-child(odd) div {
    left: 45px;
    box-shadow: -10px 0px 0px -5px white, 10px 0px 0px -5px white, 5px 5px 0px 0px #5C485E, 15px 5px 0px -5px #5C485E;
  }
  
  .timeline ul li:nth-child(odd) div::before {
    left: -15px;
    border-width: 8px 16px 8px 0;
    border-color: transparent white transparent transparent;
  }
  
  .timeline ul li:nth-child(even) div {
    left: -539px;
    box-shadow: -10px 0px 0px -5px white, 10px 0px 0px -5px white, -5px 5px 0px 0px #5C485E, -15px 5px 0px -5px #5C485E;
    
  }
  
  .timeline ul li:nth-child(even) div::before {
    right: -15px;
    border-width: 8px 0 8px 16px;
    border-color: transparent transparent transparent white;
  }
  
  card-title {
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .tl-card div{ 
    box-shadow: none !important;
  }

  .timeline .tl-title{
      display: inline !important;
      left: 0 !important;

  }

    .timeline .tl-title span{
        display: inline !important;
    }
    .timeline .tl-title span:first-of-type{
        color: #D83AEB !important;
    }

    .timeline .tl-title p{
        display: inline !important;
    }

    .timeline .tl-subtitle{
        display: block;
        width: auto !important;
        left: 0 !important;
    }

    .tl-card p{
        padding-bottom: 0;
        padding-left: 14px;
        color: black !important;

    }
  
  /* EFFECTS
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

  .delivered{
    
  }

  .timeline ul .delivered::after  {
    background-color:#D83AEB !important; 
    box-shadow: -10px 0px 0px -5px #D83AEB, 10px 0px 0px -5px #D83AEB;
  }
  
  
  /* GENERAL MEDIA QUERIES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  
  @media screen and (max-width: 1100px) {
    .timeline ul li div {
      width: 350px;
    }
    .timeline ul li:nth-child(even) div {
      left: -390px;
      /*250+45-6*/
    }
  }

  @media screen and (max-width: 850px) {
    .timeline ul li div {
      width: 250px;
    }
    .timeline ul li:nth-child(even) div {
      left: -289px;
      /*250+45-6*/
    }
  }

  @media screen and (max-width: 600px) {
    .timeline ul li {
      margin-left: 20px;

    }
    .timeline ul li div {
      width: calc(100vw - 91px);
    }
    .timeline ul li:nth-child(even) div {
      left: 45px;
      right: 200px;
    }
    .timeline ul li:nth-child(even) div::before {
      left: -15px;
      border-width: 8px 16px 8px 0;
      /* border-color: transparent #f45b69 transparent transparent; */
    }

    .timeline ul li div {
      width: 290px;
    }
  }

  @media screen and (max-width: 380px) {
    .timeline ul li {
      margin-left: 20px;

    }
    .timeline ul li div {
      width: calc(100vw - 91px);
    }
    .timeline ul li:nth-child(even) div {
      left: 45px;
      right: 200px;
    }
    .timeline ul li:nth-child(even) div::before {
      left: -15px;
      border-width: 8px 16px 8px 0;
      /* border-color: transparent #f45b69 transparent transparent; */
    }

    .timeline ul li div {
      width: 250px;
    }
  }

  @media screen and (max-width: 320px) {
    .timeline ul li {
      margin-left: 20px;

    }
    .timeline ul li div {
      width: calc(100vw - 91px);
    }
    .timeline ul li:nth-child(even) div {
      left: 45px;
      right: 200px;
    }
    .timeline ul li:nth-child(even) div::before {
      left: -15px;
      border-width: 8px 16px 8px 0;
      /* border-color: transparent #f45b69 transparent transparent; */
    }

    .timeline ul li div {
      width: 200px;
    }
  }
  
  
  /* EXTRA/CLIP PATH STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .timeline-clippy ul li::after {
    width: 40px;
    height: 40px;
    border-radius: 0;
  }
  
  .timeline-rhombus ul li::after {
    -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
            clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  }
  
  .timeline-rhombus ul li div::before {
    bottom: 12px;
  }
  
  .timeline-star ul li::after {
    -webkit-clip-path: polygon(
      50% 0%,
      61% 35%,
      98% 35%,
      68% 57%,
      79% 91%,
      50% 70%,
      21% 91%,
      32% 57%,
      2% 35%,
      39% 35%
    );
            clip-path: polygon(
      50% 0%,
      61% 35%,
      98% 35%,
      68% 57%,
      79% 91%,
      50% 70%,
      21% 91%,
      32% 57%,
      2% 35%,
      39% 35%
    );
  }
  
  .timeline-heptagon ul li::after {
    -webkit-clip-path: polygon(
      50% 0%,
      90% 20%,
      100% 60%,
      75% 100%,
      25% 100%,
      0% 60%,
      10% 20%
    );
            clip-path: polygon(
      50% 0%,
      90% 20%,
      100% 60%,
      75% 100%,
      25% 100%,
      0% 60%,
      10% 20%
    );
  }
  
  .timeline-infinite ul li::after {
    -webkit-animation: scaleAnimation 2s infinite;
            animation: scaleAnimation 2s infinite;
  }
  
  @-webkit-keyframes scaleAnimation {
    0% {
      -webkit-transform: translateX(-50%) scale(1);
              transform: translateX(-50%) scale(1);
    }
    50% {
      -webkit-transform: translateX(-50%) scale(1.25);
              transform: translateX(-50%) scale(1.25);
    }
    100% {
      -webkit-transform: translateX(-50%) scale(1);
              transform: translateX(-50%) scale(1);
    }
  }
  
  @keyframes scaleAnimation {
    0% {
      -webkit-transform: translateX(-50%) scale(1);
              transform: translateX(-50%) scale(1);
    }
    50% {
      -webkit-transform: translateX(-50%) scale(1.25);
              transform: translateX(-50%) scale(1.25);
    }
    100% {
      -webkit-transform: translateX(-50%) scale(1);
              transform: translateX(-50%) scale(1);
    }
  }


.showcase-hero{
    background-color: #FEF5FF !important;
}

/* .showcase .attr-kind-row .col:first{
    padding:0px !important
} */

.showcase-hero .row{
    padding-top: 80px !important;
    padding-bottom: 80px !important;

}

.showcase-hero button{
    margin-left: 16px;
}

.showcase-tag{
    max-height: 24px;
    padding-left: 24px;
}

.showcase-llama{
    padding: 16px;
}

.dl-button img{
    max-height: 22px;
}


.showcase-attrs img{
    padding: 12px !important;
}

.showcase .no-pad{
    padding: 0 !important;
}

.showcase-card{
    /* padding: 64px; */
}

.description-row{
    padding-bottom: 56px !important;
}

.showcase-modal .modal-wallet-btn img{
    padding: 0px;
    margin-right: 24px;
    max-width: 64px;
}

.showcase-modal .asset-dl-icon{
    margin-top: 20px;
    right: 0 !important;
}

.showcase-modal .modal-body a:hover{
    text-decoration: none !important;
}

.showcase-modal .modal-wallet-btn{
    margin-bottom: 8px;
}


