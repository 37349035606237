

.hero.row{
    background-color: #FEF5FF;
    padding-top: 80px;
    padding-bottom: 80px;

}
.hero-image{
    background-color: #FEF5FF;
    overflow: hidden;
}

.hero p{
    padding-top: 16px;
}



.home .btn{
    margin-top: 40px;
    margin-bottom: 70px;
}

.home p:first-of-type {
    padding-top: 20px;
}

.pack-copy{
    padding-bottom: 40px;
}

.packs hr{
    width: 65% !important;
}

.packs p{
    padding-top: 0px !important;

}

.packs .card-img{
    padding: 24px;
}

.cta-span{
    
    display: block !important;
    margin-top: 40px;
    margin-bottom: 40px;


    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 32px;
    padding-right: 32px;

    display: inline-block;

    background-color: #F7D8FB;
    color: #D83AEB;

    font-weight: 400 !important;
    font-family: 'Press Start 2P', cursive !important;
    font-size: 16px !important;
    line-height: 24px !important;


    -webkit-box-shadow: -10px 0px 0px -5px #F7D8FB, 10px 0px 0px -5px #F7D8FB; 
    box-shadow: -10px 0px 0px -5px #F7D8FB, 10px 0px 0px -5px #F7D8FB;

}

.padded-row{
    padding-top: 120px !important;
    padding-bottom: 120px !important;
    
}

.attr-row img{
    padding: 20px;
}

.padded-row-bottom{
    padding-bottom: 120px !important;
}

.padded-row-top{
    padding-top: 120px !important;
}

.cta-remaining-row h4{
    display: inline-block !important;
    position:relative;
    top: 0px;
    padding-right: 10px;
    padding-left: 10px;
 
  }
 
.slider {
    width: 100%;
    overflow: hidden;
    background-color: #FEF5FF;
    padding-bottom: 40px;
}
.slider .slider-row {
    width: 4968px;
    height: 216px;
    background-size: 4968px 216px;
    animation: slide 80s linear infinite;
    
}


.home .hero button{
    margin-left: 8px;
    margin-right: 8px;

}


@keyframes slide {
    from {
        background-position-x: 0;
   }
    to {
        background-position-x: 4968px;
   }
}





@media screen and (max-width: 992px) {
    .padded-row-bottom h2{
        padding-top: 24px;
    }
    .padded-row h2{
        padding-top: 24px;
    }

    .slider .slider-row {
        min-height:100%;
        /* background-image: url("./llamariver_m.png"); */
        /* display: inline-block; */
        /* width: 2592px;
        height: 216px;
        background-size: 2592px 216x; */
        animation: slide 60s linear infinite;


    }

    @keyframes slide {
        0% {
            -webkit-transform: translateX(-2000px);
        }
        50% {
            -webkit-transform: translateX(-1000px);
        }
        100% {
            -webkit-transform: translateX(0px);
        }
    }
    
  }
