.sold-out{
    margin-top: 24px !important;
    color: #D83AEB !important
}

.progress-bar{
    background-color: #D83AEB !important;
}

.progress{
    border-radius: 0% !important;
    background-color: #F7D8FB !important;
}

.llama-progress{
    margin-top: 32px;
}