.footer {
    border-top: 1px solid rgba(128, 128, 128, 0.179);
    max-height: 144px;
}

.footer .brand{
    margin-bottom: 20px;
}

.footer .brand img{

}

.footer .sexyboi img{
    max-height: 120px;
    margin-top: 20px;
}

.footer .navbar-brand{
    padding-bottom: 0 !important;
    padding-top: 0 !important;

}

.footer .socials img{
    padding-right: 20px;
}

.footer-nav-link{
    display: block;
    color: #5C485E !important;
    padding-top: 10px;
    padding-bottom: 10px;
    
}

.footer-is-active{

}

.footer-nav-link:hover{
    color: #E26BF0 !important;
    text-decoration: none;

}

.footer-header{
    color: #EFB0F7 !important;
    font-weight: 900 !important;
}

.bio{
    margin-bottom: 88px!important;
}

@media screen and (max-width: 992px) {
    .footer-links-menu{
        margin-top: 50px !important;
    }
  
  }
