.modal-backdrop{
    opacity: 0.2 !important;
}

.modal-header{
    padding: 0 !important;
    border: none !important;    
}

.modal-header p{
    padding-bottom: 24px  !important;
    padding-top: 8px  !important;

    margin-bottom: 0px  !important;

}

.modal-header h3 img{

    padding-right: 8px;
    
}

.modal h3{
    color: white !important;
    margin-bottom: 0px !important;
}

.modal h4{
    color: white !important;
}

.modal p{
    color: white !important;
}

.modal-body{
    padding: 0px !important;

}

.modal-content{
    padding: 40px;
    border: none !important;
    color: white;
    background-color: #250B28 !important;
    border-radius: 0% !important;
    
    -webkit-box-shadow: -10px 0px 0px -5px #250B28, 10px 0px 0px -5px #250B28, 15px 5px 0px -5px #250B283D, 5px 5px 0px 0px #250B283D; 
    box-shadow: -10px 0px 0px -5px #250B28, 10px 0px 0px -5px #250B28, 15px 5px 0px -5px #250B283D, 5px 5px 0px 0px #250B283D;

}

.modal-wallet-btn
{
    color: white !important;
    padding: 0px !important;
    border: none !important;
    background-color: #5C485E !important;
}

.modal-wallet-btn:hover
{
    color: black !important;
    background-color: white !important;
}

.modal-wallet-btn img{
    padding: 16px;
}

.modal .close{
    color: white !important;
    padding-right: 15px !important;
}

.modal-footer{
    padding: 0px !important;
    margin-top: 40px !important;
    display: inline-block !important;
    border-color: #5C485E  !important;
}



.modal-footer-socials{
    display: inline;
    padding: 0 !important;
    margin-left: auto !important;
    text-align: left !important;
}

.modal-footer-socials img{
    text-align: left !important;
    max-height: 24px;
    margin-top: 16px;
    margin-right: 8px;


}

.modal-dc{
    margin-top: 24px;
}

.connected-body p {
    display: inline !important;
    margin-top: 16px  !important;
}

.connected-header{
    margin-bottom: 8px;
}

.connect-btn{
    margin-left: 16px !important;
}

@media screen and (max-width: 992px) {
    .connect-btn{
       
    }

    .connect-container{
        margin-bottom: 24px;
        
        
    }
}