.last-accordian{
    padding-bottom: 80px;
}

.faqs .accordion{
    
}

.faqs .card{
    background-color: white !important;
    border-left: None !important;
    border-right: None !important;


}

.faqs .card  h4{
    margin: 0 !important;
}

.faqs .card-header{
    border: None !important;
    background-color: white !important;
    padding-top: 23px !important;
    padding-bottom: 23px !important;

}

