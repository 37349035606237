.cta-container{
    background-color: #FEF5FF;
}

.cta-container img{
    padding-right: 38px;
    padding-left: 38px;
}

.cta-container .btn{
    margin-top: 40px !important;
}

.cta-container p {
    padding-top: 46px;
}