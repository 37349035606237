

a.nav-link{
    size: 16px;
    text-align: center;
    font-weight: 700;
    padding: 27px;
    padding-left: 12px !important;
    padding-right: 12px !important;    
    color: #A89DA9 !important;
    line-height: 26px;
}
a.nav-link:hover{
    color: white !important;
    box-shadow: inset 0 -4px 0 0 #5C485E ; 
}

a.is-active{
    color: white  !important;
    box-shadow: inset 0 -4px 0 0#D83AEB; 
}

/* .navbar{
    height: 80px;
} */

.nav-bg{
    background-color: #250B28 !important;
}

.navbar-toggler{
    background-color:#250B28 !important
    ;
}

.navbar-toggler-icon{
    top: auto;
    bottom: auto;
    right: 8px;

    color: white !important;
    text-shadow: 1px 1px 1px #ccc;

}


.navbar-toggler-icon{
    border:#250B28 !important;
    background-color:#250B28 !important;
}

.navbar{
    padding-top: 0px !important;
    padding-bottom: 0px !important;

}
.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
  }
  
.custom-toggler.navbar-toggler {
    padding-right: 0px !important;
  } 

.navbar .connect-btn{
    background-color: #250B28 !important;
    border: 2px solid #5C485E !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    

}

.navbar .connect-btn:hover{
    border-color: white !important;
    background-color: #5C485E !important;
    
}

.navbar .connect-btn img{
    padding-right: 8px;
    position: relative;
    top: -2px;
}

.buy-now{
    padding-top: 8px !important;
    padding-bottom: 8px !important;

}



  @media screen and (max-width: 992px) {
    .brand{
        padding-top: 16px !important;
        padding-bottom: 16px !important;

    }



  }
