.roadmap{
    border-top: 1px solid #5C485E;

    background-color: #250B28 !important;
}

.roadmap-header{
    border-bottom: 1px solid #5C485E !important;

}

.roadmap-header .container{
  border-bottom: none !important;

}

.roadmap-header h1{
    padding-top: 80px;
    color: white !important;

}

.roadmap-header p{
    color: white !important;
    padding-top: 16px;
    padding-bottom: 70px;

}



/* TIMELINE
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.timeline ul {
    padding: 120px 0;
  }
  
  .timeline ul li {
    list-style-type: none;
    position: relative;
    width: 6px;
    margin: 0 auto;
    padding-top: 50px;
    background: #5C485E;
  }
  
  .timeline ul li::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 53%;
    transform: translateX(-50%);
    width: 20px;
    height: 30px;
    /* border-radius: 50%; */
    background: inherit;
    z-index: 1;

    -webkit-box-shadow: -10px 0px 0px -5px #5C485E, 10px 0px 0px -5px #5C485E; 
    box-shadow: -10px 0px 0px -5px #5C485E, 10px 0px 0px -5px #5C485E;
  }
  
  .timeline ul li div {
    position: relative;
    bottom: 0;
    width: 500px;
    padding: 15px;
    background: white;
    -webkit-box-shadow: -10px 0px 0px -5px white, 10px 0px 0px -5px white; 
    box-shadow: -10px 0px 0px -5px white, 10px 0px 0px -5px white;
  }

  
  .timeline ul li:nth-child(odd) div {
    left: 45px;
    -webkit-box-shadow: -10px 0px 0px -5px white, 10px 0px 0px -5px white, 5px 5px 0px 0px #5C485E, 15px 5px 0px -5px #5C485E;
    box-shadow: -10px 0px 0px -5px white, 10px 0px 0px -5px white, 5px 5px 0px 0px #5C485E, 15px 5px 0px -5px #5C485E;
  }
  
  .timeline ul li:nth-child(odd) div::before {
    left: -15px;
    border-width: 8px 16px 8px 0;
    border-color: transparent white transparent transparent;
  }
  
  .timeline ul li:nth-child(even) div {
    left: -539px;
    -webkit-box-shadow: -10px 0px 0px -5px white, 10px 0px 0px -5px white, 5px 5px 0px 0px #5C485E, 15px 5px 0px -5px #5C485E;
    box-shadow: -10px 0px 0px -5px white, 10px 0px 0px -5px white, -5px 5px 0px 0px #5C485E, -15px 5px 0px -5px #5C485E;
    
  }
  
  .timeline ul li:nth-child(even) div::before {
    right: -15px;
    border-width: 8px 0 8px 16px;
    border-color: transparent transparent transparent white;
  }
  
  card-title {
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .tl-card div{
    -webkit-box-shadow: none !important; 
    box-shadow: none !important;
  }

  .timeline .tl-title{
      display: inline !important;
      left: 0 !important;

  }

    .timeline .tl-title span{
        display: inline !important;
    }
    .timeline .tl-title span:first-of-type{
        color: #D83AEB !important;
    }

    .timeline .tl-title p{
        display: inline !important;
    }

    .timeline .tl-subtitle{
        display: block;
        width: auto !important;
        left: 0 !important;
    }

    .tl-card p{
        padding-bottom: 0;
        padding-left: 14px;
        color: black !important;

    }
  
  /* EFFECTS
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

  .delivered{
    
  }

  .timeline ul .delivered::after  {
    background-color:#D83AEB !important;
    -webkit-box-shadow: -10px 0px 0px -5px #D83AEB, 10px 0px 0px -5px #D83AEB; 
    box-shadow: -10px 0px 0px -5px #D83AEB, 10px 0px 0px -5px #D83AEB;
  }
  
  
  /* GENERAL MEDIA QUERIES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  
  @media screen and (max-width: 1100px) {
    .timeline ul li div {
      width: 350px;
    }
    .timeline ul li:nth-child(even) div {
      left: -390px;
      /*250+45-6*/
    }
  }

  @media screen and (max-width: 850px) {
    .timeline ul li div {
      width: 250px;
    }
    .timeline ul li:nth-child(even) div {
      left: -289px;
      /*250+45-6*/
    }
  }

  @media screen and (max-width: 600px) {
    .timeline ul li {
      margin-left: 20px;

    }
    .timeline ul li div {
      width: calc(100vw - 91px);
    }
    .timeline ul li:nth-child(even) div {
      left: 45px;
      right: 200px;
    }
    .timeline ul li:nth-child(even) div::before {
      left: -15px;
      border-width: 8px 16px 8px 0;
      /* border-color: transparent #f45b69 transparent transparent; */
    }

    .timeline ul li div {
      width: 290px;
    }
  }

  @media screen and (max-width: 380px) {
    .timeline ul li {
      margin-left: 20px;

    }
    .timeline ul li div {
      width: calc(100vw - 91px);
    }
    .timeline ul li:nth-child(even) div {
      left: 45px;
      right: 200px;
    }
    .timeline ul li:nth-child(even) div::before {
      left: -15px;
      border-width: 8px 16px 8px 0;
      /* border-color: transparent #f45b69 transparent transparent; */
    }

    .timeline ul li div {
      width: 250px;
    }
  }

  @media screen and (max-width: 320px) {
    .timeline ul li {
      margin-left: 20px;

    }
    .timeline ul li div {
      width: calc(100vw - 91px);
    }
    .timeline ul li:nth-child(even) div {
      left: 45px;
      right: 200px;
    }
    .timeline ul li:nth-child(even) div::before {
      left: -15px;
      border-width: 8px 16px 8px 0;
      /* border-color: transparent #f45b69 transparent transparent; */
    }

    .timeline ul li div {
      width: 200px;
    }
  }
  
  
  /* EXTRA/CLIP PATH STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .timeline-clippy ul li::after {
    width: 40px;
    height: 40px;
    border-radius: 0;
  }
  
  .timeline-rhombus ul li::after {
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  }
  
  .timeline-rhombus ul li div::before {
    bottom: 12px;
  }
  
  .timeline-star ul li::after {
    clip-path: polygon(
      50% 0%,
      61% 35%,
      98% 35%,
      68% 57%,
      79% 91%,
      50% 70%,
      21% 91%,
      32% 57%,
      2% 35%,
      39% 35%
    );
  }
  
  .timeline-heptagon ul li::after {
    clip-path: polygon(
      50% 0%,
      90% 20%,
      100% 60%,
      75% 100%,
      25% 100%,
      0% 60%,
      10% 20%
    );
  }
  
  .timeline-infinite ul li::after {
    animation: scaleAnimation 2s infinite;
  }
  
  @keyframes scaleAnimation {
    0% {
      transform: translateX(-50%) scale(1);
    }
    50% {
      transform: translateX(-50%) scale(1.25);
    }
    100% {
      transform: translateX(-50%) scale(1);
    }
  }