
.attr-text{
    padding-top: 15px;
}


.attr-kind-row{
    padding-top: 23px;
    padding-bottom: 22px;

}
.attr-kind-row:first-of-type{
    padding-top: 120px !important;
}

.attributes .attr-kind-row{
    padding-top: 80px !important;
}

.attributes .col-md-4{
    padding-bottom: 16px;
}

.rarity-dist .rarity-artifact{
    background-color: #CDAC12 !important;
}

.rarity-dist .rarity-legendary{
    background-color: #D66600 !important;
}

.rarity-dist .rarity-epic{
    background-color: #982BB4 !important;
}

.rarity-dist .rarity-rare{
    background-color: #275FB4 !important;
}


.rarity-dist .rarity-uncommon{
    background-color:#20C76D !important;
}

.rarity-dist .rarity-common{
    background-color: #CFCC89 !important;
}

.rarity-dist img{
    margin-top: 42px;
    max-height: 16px;
}

.rarity-dist h4{
    padding-top: 12px !important;
}

.rarity-dist p{
    padding-top: 0px !important;
}

.attr-kind-row img
{
    max-height: 150px;
}

.attr-kind-row:last-of-type{
    padding-bottom: 120px !important;
}

.attributes .hero{
    padding-bottom: 0px !important;
}

.attributes .nav{
    padding-top: 80px;
}

.attributes .nav-link {
    border: none !important;
    background: none !important;
    border-radius: none !important;

}

.attributes .nav-tabs{
    border-bottom: none !important;
}

.attributes .nav-item{
    color:#A89DA9 !important;

}

.attributes .nav-link{
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 24px !important;



    margin-right: 24px !important;
    margin-bottom: 0px !important;


}

.attributes .nav-item .active{
    color: #D83AEB !important;
    box-shadow: inset 0 -4px 0 0#D83AEB; 
}
.attributes .nav-link:hover{
    color: #D83AEB !important;
    box-shadow: inset 0 -4px 0 0#D83AEB60; 

}

@media screen and (max-width: 992px) {
    .rarity-dist img{
        margin-top: 16px;
    }
    .rarity-dist p{
        padding-top: 10px !important;
    }
    .rarity-tag{
        text-align: left !important;
    }

    .rarity-pct{
        text-align: right !important;
    }
  }
