

.showcase-hero{
    background-color: #FEF5FF !important;
}

/* .showcase .attr-kind-row .col:first{
    padding:0px !important
} */

.showcase-hero .row{
    padding-top: 80px !important;
    padding-bottom: 80px !important;

}

.showcase-hero button{
    margin-left: 16px;
}

.showcase-tag{
    max-height: 24px;
    padding-left: 24px;
}

.showcase-llama{
    padding: 16px;
}

.dl-button img{
    max-height: 22px;
}


.showcase-attrs img{
    padding: 12px !important;
}

.showcase .no-pad{
    padding: 0 !important;
}

.showcase-card{
    /* padding: 64px; */
}

.description-row{
    padding-bottom: 56px !important;
}

.showcase-modal .modal-wallet-btn img{
    padding: 0px;
    margin-right: 24px;
    max-width: 64px;
}

.showcase-modal .asset-dl-icon{
    margin-top: 20px;
    right: 0 !important;
}

.showcase-modal .modal-body a:hover{
    text-decoration: none !important;
}

.showcase-modal .modal-wallet-btn{
    margin-bottom: 8px;
}

