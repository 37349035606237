
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

html {
  scroll-behavior: smooth;
}


*{
  font-family: 'Inconsolata';
  
}

* img{
  /* image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated; */
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

span{
  font-family: 'Press Start 2P', cursive !important;
  position:relative;
  top: 4px;
}

.minted{
  color: #D83AEB;
}

.to-mint{
  color: #250B28;

}

.btn {
  color: white !important;
  font-weight: 900 !important;
  background-color: #D83AEB !important;
  border-color: #D83AEB !important;
  padding: 8px, 16px, 8px, 16px !important;
  -webkit-appearance: none !important;
  -webkit-border-radius: 0px !important;
}

.btn-secondary {
  color: #D83AEB !important;
  font-weight: 900 !important;
  background-color: transparent !important; 
  border: 2px solid #F7D8FB !important;
  padding: 8px, 16px, 8px, 16px !important;
  -webkit-appearance: none !important;
  -webkit-border-radius: 0px !important;
}

.btn:hover {
  background-color: #E26BF0 !important;
  color: white !important;
}

.btn-secondary:hover {
  color: #D83AEB !important;
  border: 2px solid #D83AEB !important;
  background-color: #F7D8FB !important;
}


h1 {
  color: #250B28 !important;
  font-weight: 900 !important;
  font-size: 40px !important;
  line-height: 48px !important;
}
h2 {
  color: #250B28 !important;
  font-weight: 900 !important;
  font-size: 28px !important;
  line-height: 38px !important;
}
h3 {
  color: #250B28 !important;
  font-weight: 900 !important;
  font-size: 22px !important;
  line-height: 30px !important;
}
h4 {
  color: #250B28 !important;
  font-weight: 900 !important;
  font-size: 16px !important;
  line-height: 26px !important;
}

h5{
  padding-top: 32px;
  padding-bottom: 32px;

  color: #D83AEB !important;
  font-weight: 400 !important;
  font-family: 'Press Start 2P', cursive !important;
  font-size: 16px !important;
  line-height: 16px !important;
}

a {
  color: #D83AEB !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 26px !important;
}

p{  
  color: #5C485E !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 26px !important;
}

.hero img{
  max-height: 250px;
}

.hero.row{
  padding-top: 0px !important;
}

.hero h1{
  padding-top: 80px;
}

.hero{
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;

}


