.my-sollamas span {
  color: #d83aeb;
  font-size: 12px;
  margin-left: 10px;
  top: 2px;
}

.card-container {
  display: grid;
  perspective: 700px;
}

.card-flip {
  display: grid;
  grid-template: 1fr / 1fr;
  grid-template-areas: "frontAndBack";
  transform-style: preserve-3d;
  transition: all 0.7s ease;
}

.card-flip div {
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

.front {
  grid-area: frontAndBack;
}
.back {
  grid-area: frontAndBack;
  transform: rotateY(-180deg);
}
.card-container:hover .card-flip {
  transform: rotateY(180deg);
}

.my-sollamas-card {
  margin-bottom: 40px;
}

.my-sollamas-card p {
  padding: 16px;
}

.my-sollamas-card h3 {
  padding-top: 8px;
}

.my-sollamas-card img {
  margin-bottom: 20px !important;
}

.my-sollamas-card span {
  line-height: 4px !important;
}

.my-sollamas {
  background: #fef5ff;
}

.my-sollamas .hero {
  border-bottom: 1px solid #f7d8fb !important;
}

.my-sollamas .spinner-border {
  margin-top: 24px;
}

.my-sollamas .revive-modal {
  width: 100px !important;
}

.modal-llama {
  max-width: 150px !important;
}

.my-sollamas .nav {
  padding-top: 80px;
}

.my-sollamas .nav-link {
  border: none !important;
  background: none !important;
  border-radius: none !important;
}

.my-sollamas .nav-tabs {
  border-bottom: none !important;
}

.my-sollamas .nav-item {
  color: #a89da9 !important;
}

.my-sollamas .nav-link {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 24px !important;

  margin-right: 24px !important;
  margin-bottom: 0px !important;
}

.my-sollamas .nav-item .active {
  color: #d83aeb !important;
  box-shadow: inset 0 -4px 0 0#D83AEB;
}
.my-sollamas .nav-link:hover {
  color: #d83aeb !important;
  box-shadow: inset 0 -4px 0 0#D83AEB60;
}
